// use to copy paste to create a new context file
"use client";

import { Entity } from "@bumblebee/core/buzz_model";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

// Define the shape of your context
interface PreviewContextType {
  selectedEntity: Entity | null;
  setSelectedEntity: (entity: Entity) => void;
  setIsPreviewOpen: (isOpen: boolean) => void;
  isPreviewOpen: boolean;
  togglePreview: () => void;
}

// Create the context initial value
const PreviewContext = createContext<PreviewContextType | undefined>(undefined);

// Create a provider component
export const PreviewContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const togglePreview = () => setIsPreviewOpen(!isPreviewOpen);

  // open preview drawer when entity is selected
  useEffect(() => {
    if (selectedEntity != null) {
      setIsPreviewOpen(true);
    }
  }, [selectedEntity]);

  // reset selected entity when preview is closed
  useEffect(() => {
    if (!isPreviewOpen) {
      setSelectedEntity(null);
    }
  }, [isPreviewOpen]);

  return (
    <PreviewContext.Provider
      value={{
        isPreviewOpen,
        setIsPreviewOpen,
        togglePreview,
        selectedEntity,
        setSelectedEntity,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

// Create a custom hook to use the context
export const usePreviewContext = () => {
  const context = useContext(PreviewContext);
  if (context === undefined) {
    throw new Error("usePreviewContext must be used within a PreviewContext");
  }
  return context;
};
