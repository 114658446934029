import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/app/ClientProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/components/ui/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorContextProvider"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/context/ErrorContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProjectionContextProvider"] */ "/codebuild/output/src2751084505/src/bumblebee/bumble-bi/app/src/context/ProjectionContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Theme"] */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/@chakra-ui/react/dist/esm/components/theme.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2751084505/src/bumblebee/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
