"use client";
import { ComponentType, PropsWithChildren } from "react";

import { AgentContextProvider } from "@/context/AgentContext";
import { ApiContextProvider } from "@/context/ApiContext";
import { AppContextProvider } from "@/context/AppContext";
import { DomainStackContextProvider } from "@/context/DomainStackContext";
import { LayoutContextProvider } from "@/context/LayoutContext";
import { PreviewContextProvider } from "@/context/PreviewContext";
import { QueryContextProvider } from "@/context/QueryContext";

type ProviderComponent = ComponentType<PropsWithChildren>;

const providers: ProviderComponent[] = [
  AppContextProvider,
  ApiContextProvider,
  AgentContextProvider,
  DomainStackContextProvider,
  LayoutContextProvider,
  PreviewContextProvider,
  QueryContextProvider,
];

const composeProviders = (providers: ProviderComponent[]) => {
  return ({ children }: PropsWithChildren) => {
    return providers.reduceRight(
      (acc, Provider) => <Provider>{acc}</Provider>,
      children,
    );
  };
};

const ClientProviders = composeProviders(providers);

export default ClientProviders;
