"use client";

import { Box, Button, Flex } from "@chakra-ui/react";
import Link from "next/link";
import { usePathname } from "next/navigation";

const navLinks = [
  { path: "/workbook", label: "Main View" },
  { path: "/chat", label: "Chat" },
  { path: "/debugger", label: "Debug" },
];

const Navbar = () => {
  const pathname = usePathname();

  return (
    <Box px={4} borderBottom={"1px solid gray"}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"}>
          {navLinks.map(({ path, label }) => (
            <Button
              colorScheme="blue"
              variant={pathname.includes(path) ? "surface" : "ghost"}
              asChild
              key={path}
              mr={4}
            >
              <Link href={path}>{label}</Link>
            </Button>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
