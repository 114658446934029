"use client";

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export type AncestryType = "parent" | "child";

export interface StackFrame {
  id: string;
  title: string;
  parent?: StackFrame;
  children: StackFrame[];
}

// Define the shape of your context
interface ContextType {
  stack: StackFrame;
  current: StackFrame;
  activeIds: string[];
  setCurrent: (frame: StackFrame) => void;
  getDistanceBetween: (ancestor: StackFrame, descendant: StackFrame) => number;
  currentDepth: number;
  setCurrentDepth: (n: number) => void;
  renderedFrames: Set<string>;
  notifyRenderedFrame: (id: string) => void;
}

const C3: StackFrame = { title: "Domain 3", id: "c3", children: [] };
const C2A: StackFrame = {
  title: "Domain 2A",
  id: "c2a",
  children: [],
};

const C2B: StackFrame = {
  title: "Domain 2B",
  id: "c2b",
  children: [C3],
};

const C1: StackFrame = {
  title: "Domain 1",
  id: "c1",
  children: [C2A, C2B],
};

const ROOT_FRAME: StackFrame = {
  title: "Root Domain",
  id: "root",
  children: [C1],
};

C1.parent = ROOT_FRAME;
C2A.parent = C1;
C2B.parent = C1;
C3.parent = C2B;

// Create the context initial value
const DomainStackContext = createContext<ContextType | undefined>(undefined);

// Create a provider component
const getDistanceBetween = (
  ancestor: StackFrame,
  descendant: StackFrame,
): number => {
  let current: StackFrame | undefined = descendant;
  let distance = 0;

  while (current != null && current.id !== ancestor.id) {
    distance++;
    current = current.parent;
  }

  if (current == null) {
    throw new Error(
      `Ancestor ${ancestor.id} is not in the parent chain of descendant ${descendant.id}`,
    );
  }

  return distance;
};

export const DomainStackContextProvider = ({ children }: PropsWithChildren) => {
  const [current, setCurrent] = useState<StackFrame>(ROOT_FRAME);
  const [currentDepth, setCurrentDepth] = useState<number>(0);
  const [activeIds, setActiveIds] = useState<string[]>(["root"]);
  const renderedFrames = useRef<Set<string>>(new Set());

  useEffect(() => {
    const nextActive = [current.id, current.parent?.id || "root", "root"];
    setActiveIds(nextActive);
  }, [current]);

  return (
    <DomainStackContext.Provider
      value={{
        stack: ROOT_FRAME,
        current,
        setCurrent,
        currentDepth,
        setCurrentDepth,
        activeIds,
        getDistanceBetween,
        renderedFrames: renderedFrames.current,
        notifyRenderedFrame: (id: string) => {
          renderedFrames.current.add(id);
        },
      }}
    >
      {children}
    </DomainStackContext.Provider>
  );
};

// Create a custom hook to use the context
export const useDomainStack = () => {
  const context = useContext(DomainStackContext);
  if (context === undefined) {
    throw new Error(
      "useDomainStackContext must be used within a DomainStackContext",
    );
  }
  return context;
};
